import type {ResourceCenterTranslationObj} from '../translation-keys.js';
const resourceCenterText: ResourceCenterTranslationObj = {
    Type : "タイプ",
    Topic: "トピック",
    Solution: "ソリューション",
    Industry: "業界",
    ResourceCenter: "Resource Center", // Changed to Match the child pages breadcrumbs
    // ResourceCenter: "リソースセンター",
    MCResourceCenter: "マスターコントロールリソースセンター",
    Play:"再生",
    Filter:"フィルター",
    Filters:"フィルター：",
    LoadingResources: "リソースを読込中...",
    SearchResources: "リソースを検索",
    CardView: "クリックしてカードビューを表示",
    ListView: "クリックしてリストビューを表示",
    NoMatchesFound: "選択したフィルターに一致するコンテンツが見つかりませんでした。",
    ClearFilters: "フィルターを選択し直すか、別の検索ワードを入力してください。",
    RemoveFilters: "フィルターを削除してその他のオプションを表示します。",
    FeaturedContent: "特集コンテンツ",
    PageIndicator: "{current-page-slot}/{max-page-slot}ページ"
};
export default resourceCenterText;
